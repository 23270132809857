<div class="my-profile">
  <div class="text-end">
    <img src="../../../assets/images/cross.png" class="pointer close-icon" (click)="closeProfile()" alt="Close"
      loading="lazy">
  </div>

  <div class="mt-5">
    <div class="card position-relative justify-content-center text-center p-3 mx-3">
      <img src="../../../assets/images/user.png" alt="User Image" loading="lazy" class="user-profile-img">
      <p>Jean</p>
    </div>
    <div class="card">
      <img src="../../../assets/images/designation.png" alt="Designation" loading="lazy">
      <div>
        <span>Designation</span>
        <p>Designation display</p>
      </div>
    </div>

    <div class="card">
      <img src="../../../assets/images/company.png" alt="Company" loading="lazy">
      <div>
        <span>Company</span>
        <p>Company Name</p>
      </div>
    </div>
    <div class="card">
      <img src="../../../assets/images/email.png" alt="Email" loading="lazy">
      <div>
        <span>Email Id</span>
        <p></p>
      </div>
    </div>

    <div class="card">
      <img src="../../../assets/images/phone.png" alt="Mobile Number" loading="lazy">
      <div>
        <span>Mobile No.</span>
        <p></p>
      </div>
    </div>
    <div class="card">
      <div>
        <p>Additional Email-Id</p>
        <p></p>
      </div>
      <div>
        <p>Additional Mobile No</p>
        <p></p>
      </div>

    </div>
  </div>
</div>
