import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) { }
  canActivate(): boolean {
    if (this.authService.isCompanyAdmin || this.authService.isBranchAdmin || this.authService.isGPEmployee || this.authService.isOtherEmployee) {
      return true
    } else if (this.authService.isSuperAdmin) {
      this.router.navigate(['manage-company'])
      return false
    } else {
      this.router.navigate(['404'])
      return false
    }
  }
}

