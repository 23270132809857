import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) { }
  canActivate(): boolean {
    if (this.authService.isServiceCenterManager) {
      return true
    } else {
      this.router.navigate(['404'])
      return false
    }
  }
}
