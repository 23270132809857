import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './core/loader/loader.component';
import { MessageComponent } from './core/message/message.component';
import { EUserTypes } from './enums/user.enum';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, MessageComponent, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  showHeader: boolean = false;
  currentUrl: string = '';
  EUserTypes = EUserTypes
  isCollapsedMenu: boolean = true;

  constructor(public authService: AuthService, public dataService: DataService,
    private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this.showHeader = this.authService.isLoggedIn ? true : false
      }

    })
  }

  collapseEvent(isMenuOpen: boolean) {
    this.isCollapsedMenu = isMenuOpen;
  }
  onOpenMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu
  }


}
