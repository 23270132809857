import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn:'root'
})

export class StockyardGuard implements CanActivate{
    constructor(public authService : AuthService, public router:Router){}
    canActivate():boolean{
        if(!this.authService.isStockYardManager){
            this.router.navigate(['404'])
            return false
        }
        return true;
    } 
}