<div class="container d-flex flex-column align-items-center justify-content-center">
  <div class="row">
    <div class="col-6">
      <img src="/assets/images/404.jpg" alt="404" width="100%">
    </div>
    <div class="col-6">
      <h1 class="">DigiGate</h1>
      <p>404 page not found !</p>
      @if(authService.isLoggedIn){
      @if(authService.isSuperAdmin){
      <p>back to <a routerLink='/manage-company'>manage company</a></p>
      }
      @else {
      <p>back to <a routerLink='/dashboard'>dashboard</a></p>
      }
      }@else{
      <p><a routerLink='/login'>login</a></p>
      }
    </div>
  </div>
</div>