import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({
    providedIn: 'root'
})
export class GatePassGuard implements CanActivate {
    constructor(public auth: AuthService, public router: Router) { }
    canActivate(): boolean {
        if (this.auth.isBranchAdmin || this.auth.isGPEmployee) {
            return true
        } else {
            this.router.navigate(['404'])
            return false
        }
    }
}
