import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { RouterLink, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { EUserType, EUserTypes } from '../../enums/user.enum';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { UtilityService } from '../../services/utils.service';
import { ModalComponent } from '../../common-components/modal/modal.component';
import { IModalConfig } from '../../core/interface/modal.interface';
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MyProfileComponent } from '../my-profile/my-profile.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgClass, MyProfileComponent, RouterModule, ReplacePipe, NgbCollapseModule,
    RouterLink, NgbDropdownModule, ModalComponent, NgIf],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isMenuCollapsed: boolean = false;
  EUserTypes = EUserTypes
  userType!: string;
  @ViewChild('myProfile') private myProfile!: ModalComponent;
  @Output() menuCollapse = new EventEmitter<boolean>();

  modalConfig: IModalConfig = {
    modalTitle: ''
  }

  constructor(public dataService: DataService,
    public authService: AuthService, public utilityService: UtilityService) {
  }

  ngOnInit() {
    this.userType = this.authService.userType
  }

  onLogout(): void {
    this.isMenuCollapsed = true
    this.authService.forceLogout()
  }

  // onChangePassword() {
  //   this.utilityService.onNavigate('/change-password')
  // }

  myProfileModal() {
    this.myProfile.open();
  }
  onCancel() {
    this.myProfile.dismiss();
  }

  onShowMenu(){
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.menuCollapse.emit(this.isMenuCollapsed)
  }

}
