import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const auditTrialGuard: CanActivateFn = (route, state) => {
  const auth = inject(AuthService)
  const router = inject(Router)
  if (auth.isCompanyAdmin || auth.isBranchAdmin || auth.isCustomerServiceManager) {
    return true
  } else {
    router.navigate(['404'])
    return false
  }
}
